@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
:root {
  --bg-color: #050505;
  --dark-bg: #222;
  --inverse-color: #fff;
  --prime-color: #ff5e0e;
  --delay: 0;
  --duration: 0.8s;
  --iterations: 1;
}
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  background-color: #050505;
  background-color: var(--bg-color);
}
.App {
  background-color: #050505;
  background-color: var(--bg-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}
p {
  font-family: "Roboto", sans-serif !important;
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
a:focus{
  text-decoration: none;
}
.header-bg {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  height: 9.2%;
  background-color: #ff5e0e;
  background-color: var(--prime-color);
  margin-bottom: 350px;
}
ul {
  margin-bottom: 0 !important;
}
.rowProducts{
  justify-content: center !important;
}
.appBar {
  height: 10%;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0 -12px 30px,
    rgba(0, 0, 0, 0.12) 0 4px 6px, rgba(0, 0, 0, 0.17) 0 12px 13px;
  margin-top: -25px;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #050505;
  border: 1px solid #ff5e0e;
  border: 1px solid var(--prime-color);
}
.menu-link {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.nav-item {
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
  opacity: 0.5;
  list-style: none;
  transition: opacity 0.3s ease;
}
.nav-item:hover {
  opacity: 0.8;
}
.logo {
  cursor: pointer;
  max-width: 100%;
  max-height: 65px;
  object-fit: contain;
}
.active {
  opacity: 1;
}
.sticky {
  padding-top: 25px;
  position: fixed;
  opacity: 0.85;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  width: inherit;
  top: 0;
  left: 0;
  right: 0;
}
.sticky + .content {
  padding-top: 60px;
}
.bg-icon {
  min-height: 100px;
  position: relative;
  border-left: 1px solid #464646;
  border-right: 1px solid #464646;
}
hr {
  color: #464646;
}
hr:not([size]) {
  height: 3px;
}
.bg-icon:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.25;
  background-position: right;
  margin-right: 50px;
  content: "";
  background-repeat: no-repeat;
}
.home__card {
  border: 1px solid #ff5e0e;
  border: 1px solid var(--prime-color);
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 15px;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}
.home__card > img,
.home__card > p {
  opacity: 0.3;
  padding: 5px;
  transition: opacity 0.3s ease;
}
.home__card > .sub-heading {
  font-weight: 600;
  color: #fff;
  color: var(--inverse-color);
  margin-top: 15px;
}
.home__card:hover > img {
  opacity: 0.8;
}
.home__card:hover > p {
  opacity: 0.8;
}
.home__card:hover {
  transform: scale(1.05);
}
.bg-icon.office:after {
  background-image: url("https://img.icons8.com/ios-filled/50/ffffff/marker.png");
}
.bg-icon.home:after {
  background-image: url("https://img.icons8.com/pastel-glyph/64/ffffff/taxi-office.png");
}
.bg-icon.officer1:after {
  background-image: url("https://img.icons8.com/ios-filled/60/ffffff/administrator-male--v1.png");
}
.bg-icon.officer2:after {
  background-image: url("https://img.icons8.com/ios-filled/50/ffffff/man-on-phone.png");
}
.bg-icon.email:after {
  background-image: url("https://img.icons8.com/ios-glyphs/60/ffffff/email.png");
}
.bg-icon.call:after {
  background-image: url("https://img.icons8.com/ios-glyphs/60/ffffff/outgoing-call.png");
}
.carousel-item {
  height: 80vh !important;
  width: 100% !important;
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
}
.carousel {
  margin-top: 7%;
}
.carousel-item img {
  height: 100%;
}
img {
  object-fit: cover !important;
}
.product-image {
  height: 250px;
  width: 100%;
}
.card {
  background-color: #050505;
  background-color: var(--bg-color);
  transition: transform 0.3s ease;
}
.card h3,
.card img {
  cursor: pointer;
}
.card:hover {
  transform: scale(1.03);
}
.color-white {
  color: #fff !important;
  color: var(--inverse-color) !important;
}
.color-bg {
  color: #fff !important;
  color: var(--inverse-color) !important;
}
.color-orange {
  color: #ff5e0e !important;
  color: var(--prime-color) !important;
}
.container {
  max-width: 1600px !important;
}
.container-catalogue {
  padding: 6em 0;
  background-color: #050505;
  background-color: var(--bg-color);
}
.mapElement {
  width: 100%;
}
.btn-orange {
  font-weight: 500 !important;
  color: #fff;
  color: var(--inverse-color);
  background-color: #ff5e0e;
  background-color: var(--prime-color);
  border-color: #ff5e0e;
  border-color: var(--prime-color);
  transition: opacity 0.3s ease;
}
.btn-orange:hover {
  opacity: 0.9;
}
.content-orange {
  color: #ff5e0e;
  color: var(--prime-color);
  text-transform: uppercase;
  font-weight: 600;
}
.text-justify {
  text-align: justify;
  text-align-last: center;
}
.form-control:focus {
  border-color: #ff5e0e;
  border-color: var(--prime-color);
  box-shadow: 0 0 0 0.15rem rgba(255, 166, 0, 0.25);
}
.go-home {
  text-decoration: none;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  margin-left: -3px;
}
.go-home:hover {
  opacity: 0.8;
}
.product-image-filter {
  height: 300px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in;
}
.image-text {
  position: absolute;
  width: 100%;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 3px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-image-filter:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.applications {
  list-style: none;
  font-size: 1.1em;
  cursor: pointer;
  transition: color 0.2s ease;
}
.applications:hover {
  color: #ff5e0e;
  color: var(--prime-color);
}
.filter {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in;
}
.filter > img {
  width: 100%;
}
.filter:hover {
  color: #ff5e0e;
  color: var(--prime-color);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
::selection {
  background-color: #ff5e0e;
  background-color: var(--prime-color);
  color: #fff;
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #ff5e0e;
  background-color: var(--prime-color);
  color: #050505;
  color: var(--bg-color);
  font-weight: 700;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  transition: transform 0.3s ease;
  transition: opacity 0.3s ease;
}
#myBtn:hover {
  transform: scale(0.98);
  opacity: 0.9;
}
.P404 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px !important;
  min-height: 90vh;
  background-image: url(/static/media/220886-P16W9L-44.0000050f.png);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
}
.P404 button {
  margin-top: 30em;
}
.P404 p {
  font-size: 1em;
  text-align: center;
}
.btn:hover {
  color: #fff;
  color: var(--inverse-color);
}
.footer-title:hover {
  text-decoration: none;
}
.footer-dark {
  padding: 50px 0;
  position: absolute;
  bottom: 1;
  width: 100%;
  color: #f0f9ff;
  background-color: #111;
}
.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 1.1em;
}
.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 1em;
  margin-bottom: 0;
}
.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}
.footer-dark ul a:hover {
  opacity: 0.8;
}
@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}
.footer-dark .item.text {
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}
.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}
.footer-dark .item.social {
  text-align: center;
}
@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}
.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}
.footer-dark .item.social > a:hover {
  opacity: 0.9;
}
.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.7;
  font-size: 1em;
  margin-bottom: 0;
}
@media (max-width: 1280px) {
  .home__card {
    font-size: 1em;
    align-items: center;
  }
  .home__card.p-5 {
    padding: 1rem !important;
  }
  .sticky {
    padding-top: unset;
    margin-top: 0.1px;
  }
  .carousel {
    margin-top: 10.5%;
  }
}
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
    width: 100%;
  }
  .ms-1 {
    margin: 0 !important;
  }
  .ms-2 {
    margin: 0 !important;
  }
  .ms-3 {
    margin: 0 !important;
  }
  .ms-4 {
    margin: 0 !important;
  }
  .ms-5 {
    margin: 0 !important;
  }
  .me-1 {
    margin: 0 !important;
  }
  .me-2 {
    margin: 0 !important;
  }
  .me-3 {
    margin: 0 !important;
  }
  .me-4 {
    margin: 0 !important;
  }
  .me-5 {
    margin: 0 !important;
  }
  .card p {
    display: none;
  }
  .logo {
    margin: auto;
  }
  .P404 {
    background-size: 80%;
  }
  .appBar {
    height: unset;
  }
  .sticky {
    margin-top: 2px;
    padding-top: 0;
  }
  .sticky + .content {
    padding-top: 10px;
  }
  .carousel {
    margin-top: 18%;
  }
  .carousel-item {
    height: 60vh !important;
  }
}

